import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import HomePage from "./pages/HomePage";
import CartPage from "./pages/CartPage";
import BillPage from "./pages/BillPage";
import CustomerPage from "./pages/CustomerPage";
import StatisticPage from "./pages/StatisticPage";
import ProductPage from "./pages/ProductPage";
import Register from "./pages/auth/Register";
import Login from "./pages/auth/Login";
import { useSelector } from "react-redux";
import { useEffect } from "react";

function App() {
  const cart = useSelector((state) => state.cart);

  useEffect(() => {
    localStorage.setItem("cart", JSON.stringify(cart));
  }, [cart])


  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={
          //  oturum açma durumu, yetkilendirme vb. gibi kontroller
          <RouteControl>
            <HomePage />
          </RouteControl>
        } />
        <Route path="/cart" element={
          <RouteControl>
            <CartPage />
          </RouteControl>
        } />
        <Route path="/bills" element={
          <RouteControl>
            <BillPage />
          </RouteControl>
        } />
        <Route path="/customers" element={
          <RouteControl>
            <CustomerPage />
          </RouteControl>
        } />
        <Route path="/statistic" element={
          <RouteControl>
            <StatisticPage />
          </RouteControl>
        } />
        <Route path="/products" element={
          <RouteControl>
            <ProductPage />
          </RouteControl>
        } />
        <Route path="/register" element={<Register />} />
        <Route path="/login" element={<Login />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;

export const RouteControl = ({ children }) => {
  if (localStorage.getItem("posUser")) {    // ls de posUser varsa
    return children;
  } else {
    return <Navigate to="/login" />;
  }
};